/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, } from "react";
import styles from "./style.module.scss";
import Button from "../../atoms/Button/Button";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { reset } from "store/slices/auth.slice";
import { getPersonalInfo } from "store/slices/user.slice";
import Profile from "../Profile/Profile";
import theme from "../../../assets/themes";
import UserController from "controllers/user";
import EntryFormatter from "utils/EntryFormatter";
import Icon from "components/atoms/Icon/Icon";
import constants from "utils/constants";
import Container from "components/atoms/layout/Container/Container";
import { getBillingPortalUrl } from "store/slices/card.slice";
import { toast } from "react-toastify";
const Header: React.FC<any> = ({ loggedIn, token, showMenu = true }) => {
  // const [showBuyCredits, setShowBuyCredits] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isSuccess: AuthLogoutIsSuccess } = useAppSelector((state) => state.auth.logout);
  const { billingPortalIsLoading } = useAppSelector((state) => state.cards);
  const { data } = useAppSelector((state) => state.user);
  const location = useLocation();
  const handleLogout = async () => {
    await UserController.Auth.logout({ data, dispatch });
    navigate("/signin");
  };
  useEffect(() => {
    if (!AuthLogoutIsSuccess) return;
    navigate("/signin");
    dispatch(reset());
  }, [AuthLogoutIsSuccess]);
  useEffect(() => {
    if (token && !AuthLogoutIsSuccess) dispatch(getPersonalInfo());
  }, []);
  return <Container>
    <header className={styles.header}>
      <div className={styles.header_container}>
        <Link to="/"><img src={theme["logo"]} alt="logo" className={styles.header_logo} /></Link>
        {showMenu && <>
          {loggedIn ? (
            <>
              <nav className={styles.header_menu}>
                <Link
                  to={`/${constants.PIXEL_KEYWORD}`}
                  className={[`/${constants.PIXEL_KEYWORD}`].includes(location.pathname) ? `active` : ""}
                ><div className="flex items-center gap-2 capitalize">
                  <Icon name="pulse" />
                  {constants.PIXEL_KEYWORD}
                </div></Link>
                <Link
                  to={`/${constants.LEADSEARCH_KEYWORD}`}
                  className={[`/${constants.LEADSEARCH_KEYWORD}`].includes(location.pathname) ? `active` : ""}
                ><div className="flex items-center gap-2">
                  <Icon name="search" />
                  {constants.LEADSEARCH_KEYWORD}
                </div></Link>
                <Link
                  to={`/scoring`}
                  className={[`/scoring`].includes(location.pathname) ? `active` : ""}
                ><div className="flex items-center gap-2">
                    <Icon name="score" />
                    Lead Scoring
                </div></Link>
              </nav>
              <nav className={styles.header_nav}>
                {!EntryFormatter.isObjectEmpty(data) && (
                  <>
                    {/* {!(data.hasUnlimitedCredits || data.isInvitedUser) && <>
                      <Button iconName="credits" onClick={async () => {
                        if (!data.gatewayCustomerId) {
                          navigate("/user/billing")
                          setTimeout(() => { toast.warn("Please add a payment method to your account before buying credits.") }, 900);
                          return
                        }
                        const billingData = await dispatch( getBillingPortalUrl() )
                        const url = billingData.payload.data;
                        if (!url) {
                          toast.error("Something went wrong. Please try again later.")
                          return
                        }
                        window.open(url, "_blank")
                      }}>
                        {billingPortalIsLoading ? "Redirecting..." : "Buy Credits"}
                      </Button>
                      {showBuyCredits && <Popup onClose={() => setShowBuyCredits(false)} type="modal" noButton bodyClass={styles.header_nav_popup}><BuyCredits /></Popup>}
                    </>} */}
                    <Profile
                      name={{ name: data.firstName, lastName: data.lastName, }}
                      credits={data.creditBalance}
                      unlimitedCredits={data.hasUnlimitedCredits}
                      logout={handleLogout} dropDown
                    />
                  </>
                )}
              </nav>
              <div className={styles.header_nav_burger}><BurgerMenu /></div>
            </>
          ) : (
            <nav className={styles.header_nav}>
              <Button type="primary" onClick={() => navigate("/signin")}>Log In</Button>
              <Button type="primary" onClick={() => 
                navigate("/pricing")
                // navigate("/signup")
              }>Sign Up</Button>
            </nav>
          )}
        </>}
      </div>
    </header>
  </Container>
};
export default Header;
